.slick-dots {
  bottom: -3px;
}
.slick-dots .slider-dot {
  width: 100%;
  height: 2.5px;
  background-color: #fff;
  opacity: 0.3;
  transition: all 0.3s ease-in-out;
}
.slick-active {
  .slider-dot {
    opacity: 1;
  }
}
.slick-dots li {
  margin: 0;
  width: 30px;
}
/* less than 600px */
/* @media (max-width: 1280px) {
  .slick-dots {
    bottom: -3px;
  }
} */
