.slick-next {
  left: 35px !important;
  right: unset;
  z-index: 1;
  &::before {
    display: none;
  }
}
.slick-prev {
  right: 35px !important;
  left: unset;
  z-index: 1;
  &::before {
    display: none;
  }
}
