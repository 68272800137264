.fc-button-secondary {
  background-color: blue; /* Set the background color of the secondary button */
  color: white; /* Set the text color of the secondary button */
}
.fc {
  .fc-toolbar-title {
    color: #4ea83c;
    font-weight: 700;
  }
  .fc-button-primary {
    display: none;
    background-color: #4ea83c; /* Set the background color of the primary button */
    color: white;
    &:hover {
      background-color: #3a842b;
      color: white;
    }
    &:not(:disabled) {
      &.fc-button-active {
        background-color: red;
        &:focus {
          box-shadow: unset;
        }
      }
    }
  }

  .fc-timeGridWeek-view {
    tbody {
      .fc-scrollgrid-section {
        &:last-child {
          display: none;
        }
      }
      .fc-scroller-harness {
        height: 100%;
        .fc-scroller {
          height: 100%;
          .fc-daygrid-body {
            height: 100%;
            .fc-scrollgrid-sync-table {
              height: 100%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .fc {
    .fc-toolbar-title {
      font-size: 1.5rem;
      transform: translateY(-0.5rem);
    }
  }
}

@media (max-width: 600px) {
  .fc {
    .fc-col-header-cell-cushion {
      font-size: 12px;
    }
  }
}
@media (max-width: 450px) {
  .fc {
    .fc-col-header-cell-cushion {
      font-size: 10px;
    }
  }
}
