html,
body {
  margin: 0;
  font-size: 16px;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Cairo", sans-serif;
  a {
    text-decoration: none;
    color: inherit;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif;
}
body {
  overflow-y: scroll;
  overflow-x: hidden;
}
