p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  text-align: justify;
}
